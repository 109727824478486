import { graphql } from "gatsby";
import React from "react";
import { useContext } from "react";
import Layout from "../components/layout/layout";
import PageHeader from "../components/page-header";
import { getBlokComponent } from "../components/storyblok/get-blok";
import RichtextBlok from "../components/storyblok/richtext-blok";
import { LanguageContext } from "../context/language-context";

const BlogArticle = ({ data }) => {
  const t = useContext(LanguageContext).t;
  const article = JSON.parse(data?.page?.content ?? "");
  const cats = (data?.page?.tag_list ?? [])
    .filter((tag) => tag.startsWith("c-"))
    .map(
      (tag) => t[tag.slice(2)] ?? tag.charAt(2).toUpperCase() + tag.slice(3)
    );

  const anchors = (article?.blocks ?? []).filter(
    (b) => b.component === "ancoraggio"
  );
  (article?.blocks ?? [])
    .filter((b) => b.component === "tabella_contenuti")
    .forEach((b) => {
      b.anchors = anchors;
    });

  return (
    <Layout>
      <PageHeader tit="blog" />
      <div className="ci-cont-sm my-16">
        <p className="font-bold text-ci-green">{cats.join(", ")}</p>
        <h1 className="ci-h2 ci-text30 lg:ci-text40 mt-4">{article.titolo}</h1>
        {/* <p className="mt-5 text-sm lg:text-base">{article.abstract}</p> */}
      </div>
      {/* <img
        src={article.img?.filename}
        alt={article?.img?.alt ?? ""}
        className="my-10 w-full aspect-[1] lg:aspect-[32/10] object-cover"
      /> */}
      {(article?.blocks ?? []).map(getBlokComponent)}
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogArticlePage($id: String!) {
    page: storyblokEntry(id: { eq: $id }) {
      content
      tag_list
    }
  }
`;

export default BlogArticle;
